<template>
  <div class="ui-input-json">
    <div class="json-body">
      <textarea
        v-model="stringValue"
        @input="emitInput()"
        spellcheck="false"
      ></textarea>
    </div>

    <div class="json-footer">
      <div>{{ isValid ? 'OK' : '!' }}</div>
      <button
        v-show="isValid"
        type="button"
        @click="pretify()"
      >Format</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ui-input-json",

  props: {
    value: {
      required: true
    }
  },

  data() {
    return {
      stringValue: null,
      isValid: true
    };
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        let incomingString = JSON.stringify(newValue, null, 2);
        let curString;
        try {
          curString = JSON.stringify(JSON.parse(this.stringValue), null, 2);
        } catch (err) {
          curString = null;
        }

        if (incomingString != curString) {
          this.stringValue = incomingString;
        }
      }
    }
  },

  methods: {
    emitInput() {
      try {
        this.$emit("input", JSON.parse(this.stringValue));
        this.isValid = true;
      } catch (err) {
        this.isValid = false;
      }
    },

    pretify() {
      try {
        this.stringValue = JSON.stringify(
          JSON.parse(this.stringValue),
          null,
          2
        );
      } catch (err) {
        // zzzzzz
      }
    }
  }
};
</script>

<style lang="scss">
.ui-input-json {
  .json-body {
    min-width: 350px;

    & > textarea {
      min-height: 140px;
      resize: vertical;

      border: 1px solid rgba(0, 0, 0, 0.15);

      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      display: block; /*reset from inline*/
      width: 100%;
      margin: 0; /*remove defaults*/
      padding: 4px;

      overflow-y: auto; /*resets IE*/
      overflow-x: hidden; /*resets IE*/
    }
  }

  .json-footer {
    display: flex;
    align-items: center;

    font-size: 11px;
    padding: 3px;
    margin: 0;

    background-color: rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-top: 0;

    & > div {
      flex: 1;
      padding: 0 12px;
    }
  }
}
</style>